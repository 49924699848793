import React from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();


  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleServiceClick = () => {
    window.scrollTo(0, 0);
    navigate('/service'); // Assuming you have defined the 'navigate' function
  };

  const handleHomeClick = () => {
    window.scrollTo(0, 0);
    navigate('/'); // Replace '/home' with the correct route for Home page
  };

  const handleWorkClick = () => {
    window.scrollTo(0, 0);
    navigate('/work'); // Replace '/work' with the correct route for Work page
  };

  const handleOfficeClick = () => {
    window.scrollTo(0, 0);
    navigate('/office'); // Replace '/office' with the correct route for Office page
  };

  const handleAboutClick = () => {
    window.scrollTo(0, 0);
    navigate('/about'); // Replace '/about' with the correct route for About page
  };

  const handleContactClick = () => {
    window.scrollTo(0, 0);
    navigate('/contact2'); // Replace '/contact' with the correct route for Contact Us page
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 400) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <nav
        className={`sticky top-0 bg-white py-4 px-6 shadow flex justify-between z-10 ${
          isOpen ? 'flex-col h-[300px]' : 'h-[80px] md:h-[100px] lg:h-[100px]'
        }`}
      >
        <div className="flex gap-2 items-center">
          <img src="/Assets/WhiteSheet_logo_RED.png" className=" nav-img p-7 left-0 w-[250px] md:w-[280px] lg:w-[300px]" alt="Logo" />
          <div >
          <HamburgerMenu
            isOpen={isOpen}
            menuClicked={handleToggle}
            width={20}
            height={20}
            strokeWidth={2}
            rotate={0}
            color="#000"
            borderRadius={3}
            animationDuration={0.5}
            className="hamburger cursor-pointer md:hidden "
          />
          </div>
        </div>

        <ul
          className={`flex flex-row space-x-3 justify-items-start items-start pt-5 text-black text-base cursor-pointer ${
            isOpen ? 'flex-col text-left ' : 'hidden md:flex'
          }`}
        >
          <li onClick={handleHomeClick} className={`text-gray-600 hover:text-gray-900 pl-3 ${location.pathname === '/' ? 'font-bold' : ''}`}>Home</li>
          <li onClick={handleAboutClick} className={`text-gray-600 hover:text-gray-900 ${location.pathname === '/about' ? 'font-bold' : ''}`}>About</li>
          <li onClick={handleServiceClick} className={`text-gray-600 hover:text-gray-900 ${location.pathname === '/service' ? 'font-bold' : ''}`}>Services</li>
          <li onClick={handleWorkClick} className={`text-gray-600 hover:text-gray-900 ${location.pathname === '/work' ? 'font-bold' : ''}`}>Work</li>
          <li onClick={handleOfficeClick} className={`text-gray-600 hover:text-gray-900 ${location.pathname === '/office' ? 'font-bold' : ''}`}>Offices</li>
          <li onClick={handleContactClick} className={`text-gray-600 hover:text-gray-900 ${location.pathname === '/contact2' ? 'font-bold' : ''}`}>Contact</li>
        </ul>
      </nav>
    </div>
  );
}
