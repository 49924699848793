import React from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Navbar from './Navbar';
import '../index.css';
export default function Office() {

  return (
    <div className='overflow-hidden'>
      <Navbar/>
    <div  className="aboutus relative w-full">
  <img src="/Assets/WS_red.png" className=" top-0 w-full bg-no-repeat bg-cover top-0 h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh]" />
  <img src="/Assets/WhiteSheet_W_blk.png" className=" top-0 absolute left-[-50%] w-[100%] md:w-[100%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh]" />
  <div className=" about absolute top-0 flex flex-col space-y-5  md:flex lg:flex xl:flex md:flex-row lg:flex-row xl:flex-row md:space-x-[8%] lg:space-x-[20%] xl:space-x-[25%] p-10 md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-10 xl:py-10  ">
    <div className="text-left md:text-left">
      <h1 className="text-white text-4xl md:text-6xl lg:text-6xl xl:text-6xl">Offices</h1>
    </div>
    <div className="text-white text-left relative">
      <img src="/Assets/WS_conta us pg mag background.png" className="  top-0" />
      <div className="absolute top-0 pl-[25%]">
        <p>
          ASIA PACIFIC/INDIA<br />
          WZ-298A, Naraina, <br />
          New Delhi-110028. INDIA <br />
          info@whitesheet.in | sourav@whitesheet.in<br /><br />

          OCEANIC/AUSTRALIA <br />
          105, Lineham Drive, Cranbourne East, <br />
          Vic 3977 Australia <br />
          info@whitesheet.in <br /><br />

          www.whitehseet.in
        </p>
        <div className="w-[100px] flex gap-2">
        <a href='https://linkedin.com/company/whitesheet/'><img src="/Assets/Linkedin_logo.png" className=" h-3" /></a>
        <a href='https://instagram.com/whitesheetmediawork/'> <img src="/Assets/Insta_logo.png" className=" h-3" /></a>
        <a href='https://www.facebook.com/whitesheetmedia/'><img src="/Assets/FB_logo.png" className=" h-3" /></a>
        <a href='https://in.pinterest.com/whitesheetmediaworks'><img src="/Assets/Pinterest_logo.png" className=" h-3" /></a>
        <a href='https://www.youtube.com/@WhiteSheetMediaWorks'><img src="/Assets/Youtube_logo.png" className=" h-3" /></a>
        </div>
        <p className="pt-[100px]">
          ©2023 White Sheet Media Work (P) Limited. All Right Reserved.
        </p>
      </div>
    </div>
  </div>
</div>
    </div>
  );
}

