import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Main';
import ContactUs2 from './pages/contacus2';
import Office from './pages/office';
import Service from './pages/Servise';
import Work from './pages/Work';
import TermsAndConditionsModal from './pages/TermsAndConditionsModal';
import CookiesPopup from './pages/CookiesPopup';
import AboutUs from './pages/About';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Service" element={<Service />} />
          <Route path="/work" element={<Work />} />
          <Route path="/office" element={<Office />} />
          <Route path="/T&c" element={<TermsAndConditionsModal />} />
          <Route path="/contact2" element={<ContactUs2 />} />
          <Route path="/about" element={<AboutUs />} />
        </Routes>
        <CookiesPopup />
      </BrowserRouter>
    </div>
  );
}

export default App;
