import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import '../index.css';
import Swal from 'sweetalert2';


export default function Service() {
  const contactRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_fname: '',
    user_lname: '',
    user_email: '',
    subject: '',
    message: '',
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Check if all form fields are filled
    if (
      formData.user_fname &&
      formData.user_lname &&
      formData.user_email &&
      formData.subject &&
      formData.message
    ) {
      // Show a sweet alert when all fields are filled
      Swal.fire({
        icon: 'success',
        title: 'Thank You',
        text: 'We will revert to you shortly',
      });
      setFormData({
        user_fname: '',
        user_lname: '',
        user_email: '',
        subject: '',
        message: '',
      });
    } else {
      // If not all fields are filled, you can show an error alert
      Swal.fire({
        icon: 'error',
        title: 'Form Submission Error',
        text: 'Please fill out all the required fields.',
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleWorkClick = () => {
    navigate('/work');
  };
  const handlehome = () => {
    navigate('/');
  };
  const handleContactClick = () => {
  navigate('/contact2')
  };
  const handleAboutClick = () => {
    navigate('/about');
    };
    const handleOfficeClick = () => {
      navigate('/office');
      };
      const handleServiceClick = () => {
        navigate('/service')
        };
 

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="relative overflow-hidden">
      <nav
        className={`sticky top-0 bg-white bg-opacity-25 z-50 py-4 px-6 shadow flex justify-between z-10 ${
          isOpen ? 'flex-col h-[300px]' : 'h-[100px]'
        }`}
       
      >
        <div className="flex gap-2 items-center">
          <img  
            src="/Assets/WhiteSheet_logo_white.png"
            className=" nav-img   p-7 left-0 w-[250px] md:w-[280px] lg:w-[300px]"
            alt="Logo"
          />
          <div>
            <HamburgerMenu
            isOpen={isOpen}
            menuClicked={handleToggle}
            width={20}
            height={20}
            strokeWidth={2}
            rotate={0}
            color="#000"
            borderRadius={3}
            animationDuration={0.5}
            className="hamburger cursor-pointer md:hidden "
          />
          </div>

          
        </div>

        <ul
          className={`flex flex-row space-x-3  pt-5 justify-items-start items-start text-white text-base cursor-pointer ${
            isOpen ? 'flex-col text-left ' : 'hidden md:flex'
          }`}
        >
          <li onClick={handlehome} className="text-white hover:text-gray-900 pl-3">
            Home
          </li>
          <li onClick={handleAboutClick} className="text-white hover:text-gray-900">About</li>
          <li onClick={handleServiceClick} className="text-white hover:text-gray-900 font-bold">Services</li>
          <li onClick={handleWorkClick} className="text-white hover:text-gray-900">
            Work
          </li>
          <li onClick={handleOfficeClick} className="text-white hover:text-gray-900">Offices</li>
          <li onClick={handleContactClick} className="text-white hover:text-gray-900">
            Contact Us
          </li>
        </ul>
      </nav>
      <div>
      <video
        src="/Assets/WS_service pg_Logo_mono.mp4"
        className="w-full h-[750px] object-cover top-0 absolute"
        autoPlay
        loop
        muted
      ></video>

      <div className="relative z-10">
        <div className="text-white text-left font-extralight px-10 py-24">
          <p className="leading-6">
            <b className="text-6xl">Fresh. Engaging.</b> <br />
            <b className="text-6xl">Powerful.</b>
            <br />
            <b className="text-xl">
              We create integrated, valuable and disruptive brand experiences.
              <br />
              Drive brand to get stunning results.
            </b>
          </p>
        </div>
      </div>

      <div className="w-full bg-black h-[650px] md:h-[650px] lg:h-[100vh] xl:h-[100vh] relative mt-40">
      <img   src="/Assets/WhiteSheet_W_RED.png" className=" home-img   absolute right-[-50%] w-[100%] md:w-[90%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[650px] md:h-[650px] lg:h-[100vh] xl:h-[100vh]" />

        <div className="absolute top-0 flex flex-col md:flex md:flex-row lg:flex lg:flex-row xl:flex xl:flex-row  md:space-x-[10%] lg:space-x-[15%] xl:space-x-[15%] p-6 md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-20 xl:py-24 ">
          <div className="text-white text-left">
            <div>
            <p className="text-white text-3xl md:text-5xl lg:text-6xl xl:text-6xl">EXPERTISE</p>

            </div>
            
             <div className='mt-5 md:mt-5 lg:mt-10 xl:mt-10'>
             <p className="text-white leading-4 md:leading-4 lg:leading-5 xl:leading-5 ">
              WE CREATE TRANSFORMATIVE & DISRUPTIVE BRAND EXPERIENCES FOR
              SUSTAINABLE GROWTH AND SUCCESS.
              <br />
              <br />
              We’re strategic experts and creative explorers committed to solve
              complex challenges. With smart tools and resources, we examine
              the implications of every brand’s choices, create new experiences
              and open doors to opportunities. Our expertise includes...
              <br />
              <br />
              Brand Strategy (Brand purpose, positioning, consumers, portfolio)
              <br />
              Brand Expression (Brand’s visual identity, packaging, naming & verbal)<br />
              Brand Experience (Brand’s consumer journey, consumer experience)<br />
              Brand Engagement (Brand’s assets, culture, consumers interaction & narratives)<br />
              Brand Communication (Brand’s TV, Print, Radio, Digital, OOH, Retail expression)<br />
              Brand Interaction & media design (Brand’s media strategy, usages & execution) .<br />
              Brand Innovation (Brand’s assets innovation and execution)
            </p>
             </div>
          </div>
          <div className="w-full flex items-center justify-center ">
            <img   src="/Assets/Expertise page_graph.png" className="w-[40%] md:w-[100%] lg:w-[100%] xl:w-[100%] pt-5 md:pt-10 lg:pt-14 xl:pt-14 " />
          </div>
        </div>
      </div>



      <div ref={contactRef} >
      <div  className="relative w-full">
  <img   src="/Assets/WS_red.png" className=" top-0 w-full bg-no-repeat bg-cover top-0 h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]" />
  <img   src="/Assets/WhiteSheet_W_blk.png" className=" top-0 absolute left-[-50%] w-[100%] md:w-[100%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]" />
  <div className=" contact absolute top-0 flex flex-col space-y-10  md:flex md:flex-row md:space-x-[10%] lg:flex lg:flex-row xl:flex-row xl:flex lg:space-x-[10%] xl:space-x-[10%] p-[1%] md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-24 xl:py-28  ">
    <div className="text-left md:text-left mt-6 ">
      <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-6xl">Get&nbsp;In&nbsp;Touch</h1>
    </div>
    <div className="text-white top-0 text-left relative">
      <img   src="/Assets/WS_conta us pg mag background.png" className="w-700" />
      <div className="absolute  top-0 flex flex-col space-y-5 ml-4 lg:ml-[20%] xl:ml-[20%]">
        <div className='flex   space-x-5 md:space-x-10'>
          <div className='flex flex-col space-y-5'>
             <p>
                  ASIA PACIFIC/INDIA<br />
                  WZ-298A, Naraina,<br />
                  New Delhi-110028. INDIA <br />
                  info@whitesheet.in <br/>
                  sourav@whitesheet.in
                </p>
                <p>www.whitehseet.in</p>

          </div>
          <div className='flex flex-col  space-y-5'>
          <p>
                OCEANIC/AUSTRALIA <br/>
                105, Lineham Drive,<br/>
                Cranbourne East, <br/>
                Vic 3977 Australia <br/>
                info@whitesheet.in

                </p>
                <div className="w-[150px] flex gap-2 pt-2">
                <a href='https://linkedin.com/company/whitesheet/'><img   src="/Assets/Linkedin_logo.png" className=" h-3" /></a>
                <a href='https://instagram.com/whitesheetmediawork/'> <img   src="/Assets/Insta_logo.png" className=" h-3" /></a>
                <a href='https://www.facebook.com/whitesheetmedia/'><img   src="/Assets/FB_logo.png" className=" h-3" /></a>
                <a href='https://in.pinterest.com/whitesheetmediaworks'><img   src="/Assets/Pinterest_logo.png" className=" h-3" /></a>
                <a href='https://www.youtube.com/@WhiteSheetMediaWorks'><img   src="/Assets/Youtube_logo.png" className=" h-3" /></a>                </div>
              

          </div>

        </div>
        <div>

        <form className="contact-inputs bg-white p-1 md:px-2 w-[95%]" onSubmit={handleFormSubmit} >
                  <div className="flex text-black space-x-2">
                    <input className=" w-[50%]" placeholder="First Name" type='text' name='user_fname' autoComplete='off' required onChange={handleInputChange}/>
                    <input className=" w-[50%] " placeholder="Last Name" type='text' name='user_lname' autoComplete='off' required onChange={handleInputChange} />
                  </div>
                  <div className="flex mt-2 space-x-2 text-black">
                    <input className=" w-[50%] " placeholder="Email" type='email' name='user_email' autoComplete='off' required onChange={handleInputChange} />
                    <input className=" w-[50%] " placeholder="Company" type='text' name='subject' autoComplete='off' required onChange={handleInputChange}/>
                  </div>
                  <div className="mt-2 text-black">
                    <textarea placeholder="Add your message here" className="w-full" name='message' required onChange={handleInputChange}/>
                  </div>
                  <div className='flex md:flex  md:space-x-1'>
                    <div className='flex flex-col text-black text-xs'>
                      <div>                     
                      <input type="checkbox" required/><lable> I understand and agree to the terms of submission & data processing. </lable>
                      </div>
                      <div>
                      <input type='checkbox' required /><lable> I’m not a robot. </lable>
                      </div>
                    </div>
                    <div>
                      <button className='text-white bg-black md:w-16 rounded' type='submit' >Submit</button>
                    </div>
                  </div>
        </form>

        <p className="pt-5">
                ©2023 White Sheet Media Work (P) Limited. All Right Reserved.
              </p>

        </div>
      
        
      </div>
    </div>
  </div>
</div>
      </div>
      </div>

    </div>
  );
}











