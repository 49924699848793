import React from 'react';

const TermsAndConditionsModal = () => {
    return (
        <div className="modal bg-black text-white p-4 md:p-8 lg:p-12 xl:p-16">
            <div className="modal-content bg-red-500 p-4 md:p-8 lg:p-12 xl:p-16 rounded-lg shadow-lg">
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">Terms & Conditions</h2>
            <p className="text-sm md:text-base">

                    By accessing and using this website, you agree to be bound by the following Terms & Conditions ("terms"). Please read them carefully. If you do not agree with these terms, you should refrain from accessing or viewing this website.
                </p>
                <ol className="text-sm md:text-base mt-4">
                    <li className="mb-4">
                        The information provided on this website is for general informational purposes only. White Sheet Media Works has made reasonable efforts to ensure the accuracy of the information on this website; however, there may be occasional inaccuracies and errors for which White Sheet Media Works apologizes.
                    </li>
                    <li>
                        White Sheet Media Works does not make any representations or warranties about the information provided through this website, including any hypertext links to other websites or external resources. White Sheet Media Works assumes no liability for any inaccuracies or omissions on this website, and any decisions based on the information contained herein are the sole responsibility of the visitor.
                    </li>
                    <li>
                        The information and images contained on this website are the copyright of White Sheet Media Works. You are permitted to make copies of this website for necessary incidental acts during your viewing of it. Additionally, you may take a printout for personal use, as long as it is reasonable and for private purposes. However, you are not allowed to frame this site or link to any page other than the home page without the express written permission of White Sheet Media Works.
                    </li>
                    <li>
                        White Sheet Media Works reserves the right, at any time and without notice, to enhance, modify, alter, suspend, or permanently discontinue all or any part of this website and to restrict or prohibit access to it.
                    </li>
                    <li>
                        By using this website, you agree to indemnify White Sheet Media Works against any costs, claims, losses, and damages (including legal fees) incurred by or awarded against White Sheet Media Works as a result of your misuse of this website or your breach of these terms.
                    </li>
                    <li>
                        In the event of a breach of these terms or White Sheet Media Works' Privacy Policy, White Sheet Media Works may, at its option, suspend or block your access to this website and refuse to provide any further access to it.
                    </li>
                    <li>
                        This website is provided to you free of charge, and neither White Sheet Media Works nor any of its subsidiary or affiliated companies accept any liability to you (except in cases of personal injury or death caused by negligence or for fraud or as required by law) in connection with this website, whether in contract, tort (including negligence), or otherwise. White Sheet Media Works accepts no liability for any direct, special, indirect, or consequential damages or any other damages of any kind resulting from the use of any information obtained directly or indirectly from this website. Your sole remedy is to discontinue using this website.
                    </li>
                    <li>
                        These terms may be amended by White Sheet Media Works from time to time.
                    </li>
                    <li>
                        Your use of this website, any downloads from it, and the operation of these terms and conditions shall be governed by the laws of England and Wales. The English courts shall have exclusive jurisdiction over any dispute arising from your use of this website.
                    </li>
                </ol>
                <p className="text-sm md:text-base mt-4">
                    In the event that any term or part of the terms contained herein is determined by a competent authority to be invalid, unlawful, or unenforceable to any extent, such term shall be severed to that extent from the remaining terms, which shall continue to be valid and enforceable to the fullest extent permitted by law. These Terms and Conditions were last modified on July 1, 2023.
                </p>
                <p className="text-sm md:text-base mt-4">
                    Please ensure that these terms and conditions and the privacy policy are reviewed, modified, and checked for plagiarism according to the specific requirements of White Sheet Media Works.
                </p>
            </div>
        </div>
    );
}

export default TermsAndConditionsModal;