import React from 'react';
import { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import '../index.css';
import Swal from 'sweetalert2';

export default function Work() {
  const DainikRef = useRef(null);
  const GoodrichRef = useRef(null);
  const News18kRef = useRef(null);
  const AirworkRef = useRef(null);
  const ptcRef = useRef(null);
  const DevaRef = useRef(null);
  const infinityRef = useRef(null);
  const LubzolRef = useRef(null);
  const indianewsRef = useRef(null);
  const platinaRef = useRef(null);
  const AltkRef = useRef(null);
  const VETROMODARef = useRef(null);
  const ieema = useRef(null)
  const ROYALkRef = useRef(null);
  const CHHABRARef = useRef(null);
  const LUXCRAFTRef = useRef(null);
  const SUPVAUNIVERSITYRef = useRef(null);
  const HWSSIRef = useRef(null);
  const HORIZONOCEANICRef = useRef(null);
  const navigate = useNavigate();

  const [isPlaying, setIsPlaying] = useState(true);
  const [isPlayingInext, setIsPlayingInext] = useState(true);
  const [isPlayingLubzol, setIsPlayingLubzol] = useState(true);
  const [isPlayingPlatina, setIsPlayingPlatina] = useState(true);
  const [isPlayingInfinity, setIsPlayingInfinity] = useState(true);
  const [isPlayingLuxcraft, setIsPlayingLuxcraft] = useState(true);

  const videoRef = useRef(null);
  const InextRef = useRef(null);
  const LubzolvRef = useRef(null);
  const PlatinaVRef = useRef(null);
  const InfinityVRef = useRef(null);
  const LuxcraftVRef = useRef(null);

  const [formData, setFormData] = useState({
    user_fname: '',
    user_lname: '',
    user_email: '',
    subject: '',
    message: '',
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Check if all form fields are filled
    if (
      formData.user_fname &&
      formData.user_lname &&
      formData.user_email &&
      formData.subject &&
      formData.message
    ) {
      // Show a sweet alert when all fields are filled
      Swal.fire({
        icon: 'success',
        title: 'Thank You',
        text: 'We will revert to you shortly',
      });
      setFormData({
        user_fname: '',
        user_lname: '',
        user_email: '',
        subject: '',
        message: '',
      });
    } else {
      // If not all fields are filled, you can show an error alert
      Swal.fire({
        icon: 'error',
        title: 'Form Submission Error',
        text: 'Please fill out all the required fields.',
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleVideo = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
  
      if (InextRef.current && !InextRef.current.paused) {
        InextRef.current.pause();
        setIsPlayingInext(false);
      }
      if (LubzolvRef.current && !LubzolvRef.current.paused) {
        LubzolvRef.current.pause();
        setIsPlayingLubzol(false);
      }
      if (InfinityVRef.current && !InfinityVRef.current.paused) {
        InfinityVRef.current.pause();
        setIsPlayingInfinity(false);
      }
      if (PlatinaVRef.current && !PlatinaVRef.current.paused) {
        PlatinaVRef.current.pause();
        setIsPlayingPlatina(false);
      }
      if (LuxcraftVRef.current && !LuxcraftVRef.current.paused) {
        LuxcraftVRef.current.pause();
        setIsPlayingLuxcraft(false);
      }
  
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  
  const toggleVideo1 = () => {
    if (InextRef.current.paused) {
      InextRef.current.play();
      setIsPlayingInext(true);
  
      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
      if (LubzolvRef.current && !LubzolvRef.current.paused) {
        LubzolvRef.current.pause();
        setIsPlayingLubzol(false);
      }
      if (InfinityVRef.current && !InfinityVRef.current.paused) {
        InfinityVRef.current.pause();
        setIsPlayingInfinity(false);
      }
      if (PlatinaVRef.current && !PlatinaVRef.current.paused) {
        PlatinaVRef.current.pause();
        setIsPlayingPlatina(false);
      }
      if (LuxcraftVRef.current && !LuxcraftVRef.current.paused) {
        LuxcraftVRef.current.pause();
        setIsPlayingLuxcraft(false);
      }
  
    } else {
      InextRef.current.pause();
      setIsPlayingInext(false);
    }
  };
  const toggleVideo2 = () => {
    if (LubzolvRef.current.paused) {
      LubzolvRef.current.play();
      setIsPlayingLubzol(true);

      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
  
      if (InextRef.current && !InextRef.current.paused) {
        InextRef.current.pause();
        setIsPlayingInext(false);
      }
      if (InfinityVRef.current && !InfinityVRef.current.paused) {
        InfinityVRef.current.pause();
        setIsPlayingInfinity(false);
      }
      if (PlatinaVRef.current && !PlatinaVRef.current.paused) {
        PlatinaVRef.current.pause();
        setIsPlayingPlatina(false);
      }
      if (LuxcraftVRef.current && !LuxcraftVRef.current.paused) {
        LuxcraftVRef.current.pause();
        setIsPlayingLuxcraft(false);
      }
  
    } else {
      LubzolvRef.current.pause();
      setIsPlayingLubzol(false);
    }
  };

  const toggleVideo3 = () => {
    if (PlatinaVRef.current.paused) {
      PlatinaVRef.current.play();
      setIsPlayingPlatina(true);

      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
  
      if (InextRef.current && !InextRef.current.paused) {
        InextRef.current.pause();
        setIsPlayingInext(false);
      }
      if (LubzolvRef.current && !LubzolvRef.current.paused) {
        LubzolvRef.current.pause();
        setIsPlayingLubzol(false);
      }
      if (InfinityVRef.current && !InfinityVRef.current.paused) {
        InfinityVRef.current.pause();
        setIsPlayingInfinity(false);
      }
      if (LuxcraftVRef.current && !LuxcraftVRef.current.paused) {
        LuxcraftVRef.current.pause();
        setIsPlayingLuxcraft(false);
      }
  
    } else {
      PlatinaVRef.current.pause();
      setIsPlayingPlatina(false);
    }
  };

  const toggleVideo4 = () => {
    if (InfinityVRef.current.paused) {
      InfinityVRef.current.play();
      setIsPlayingInfinity(true);

      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
  
      if (InextRef.current && !InextRef.current.paused) {
        InextRef.current.pause();
        setIsPlayingInext(false);
      }
      if (LubzolvRef.current && !LubzolvRef.current.paused) {
        LubzolvRef.current.pause();
        setIsPlayingLubzol(false);
      }
      if (PlatinaVRef.current && !PlatinaVRef.current.paused) {
        PlatinaVRef.current.pause();
        setIsPlayingPlatina(false);
      }
      if (LuxcraftVRef.current && !LuxcraftVRef.current.paused) {
        LuxcraftVRef.current.pause();
        setIsPlayingLuxcraft(false);
      }
  
    } else {
      InfinityVRef.current.pause();
      setIsPlayingInfinity(false);
    }
  };

  const toggleVideo5 = () => {
    if (LuxcraftVRef.current.paused) {
      LuxcraftVRef.current.play();
      setIsPlayingLuxcraft(true);

      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
  
      if (InextRef.current && !InextRef.current.paused) {
        InextRef.current.pause();
        setIsPlayingInext(false);
      }
      if (LubzolvRef.current && !LubzolvRef.current.paused) {
        LubzolvRef.current.pause();
        setIsPlayingLubzol(false);
      }
      if (PlatinaVRef.current && !PlatinaVRef.current.paused) {
        PlatinaVRef.current.pause();
        setIsPlayingPlatina(false);
      }
      if (InfinityVRef.current && !InfinityVRef.current.paused) {
        InfinityVRef.current.pause();
        setIsPlayingInfinity(false);
      }
  
    } else {
      LuxcraftVRef.current.pause();
      setIsPlayingLuxcraft(false);
    }
  };
  
  
  

  useEffect(() => {
    //const playPromise = videoRef.current.play();
  

    // if (playPromise !== undefined) {
    //   playPromise
    //     .then(() => {
    //       setIsPlaying(true);
    //     })
    //     .catch((error) => {
    //       console.error('Autoplay failed:', error);
    //       setIsPlaying(false);
    //     });
    // }
   
    // const playPromise1 = InextRef.current.play();
    // if (playPromise1 !== undefined) {
    //   playPromise1
    //     .then(() => {
    //       setIsPlayingInext(true);
    //     })
    //     .catch((error) => {
    //       console.error('Autoplay failed:', error);
    //       setIsPlayingInext(false);
    //     });
    // }

    setIsPlaying(false);
    setIsPlayingInext(false);
    setIsPlayingLubzol(false);
    setIsPlayingInfinity(false);
    setIsPlayingPlatina(false);
    setIsPlayingLuxcraft(false);


  }, []);




  const handleVisibilityChange = () => {
    if (document.hidden) {
      // Page is hidden, so pause the video.
      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
      if(!InextRef.current.paused){
        InextRef.current.pause();
        setIsPlayingInext(false)
      }
      if(!LubzolvRef.current.paused){
        LubzolvRef.current.pause();
        setIsPlayingLubzol(false)
      }
      if(!InfinityVRef.current.paused){
        InfinityVRef.current.pause();
        setIsPlayingInfinity(false)
      }
      if(!PlatinaVRef.current.paused){
        PlatinaVRef.current.pause();
        setIsPlayingPlatina(false)
      }
      if(!LuxcraftVRef.current.paused){
        LuxcraftVRef.current.pause();
        setIsPlayingLuxcraft(false)
      }
    }
  };
  

  useEffect(() => {
    // Add event listener for visibility change when the component mounts.
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Remove the event listener when the component unmounts.
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
 
  const handleDainikClick = () => {
    DainikRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handlegoodrichClick = () => {
    GoodrichRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleairworkClick = () => {
    AirworkRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handlenews18Click = () => {
  };
  const handleindianewsClick = () => {
    indianewsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleptcClick = () => {
    ptcRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleplatinaClick = () => {
    platinaRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleinfinityClick = () => {
    infinityRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handlelubzolClick = () => {
    LubzolRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handlealtClick = () => {
    AltkRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handlevetromodaClick = () => {
    VETROMODARef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleroyalClick = () => {
    ROYALkRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handlechhabraClick = () => {
    CHHABRARef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handledevaClick = () => {
    DevaRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleluxcraftClick = () => {
    LUXCRAFTRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handlehwssiClick = () => {
    HWSSIRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handlesupvaClick = () => {
    SUPVAUNIVERSITYRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handlehorizonClick = () => {
  };
    const handleieemaClick = () => {
    ieema.current.scrollIntoView({ behavior: 'smooth' });
  };



 
  return (
    <div>
      {/* Sticky Navbar */}
      <div className=" sticky top-0 z-50">
   <Navbar />
   </div>



      <div className=' relative overflow-hidden' >

      <div className="w-full h-[600px] md:h-[600px] xl-[600px] lg:h-[600px]">
        <img alt='a' src="/Assets/WS_red.png" className="w-full absolute bg-no-repeat bg-cover w-full top-0 h-[600px] md:h-[600px] lg:h-[600px] xl-[600px]" />

        <img alt='a' src="/Assets/WhiteSheet_W_blk.png" className="absolute top-0 right-[-50%] w-[100%] md:w-[100%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[600px] md:h-[600px] xl-[600px] lg:h-[600px]" />
      <div className="absolute work flex flex-col md:flex md:flex-row  lg:flex lg:flex-row md:space-x-[5%] lg:space-x-[15%] xl:space-x-[15%] px-5 py-20">
        <div className='w-1/2 md:w-1/2 text-white text-left'>
            <p className='text-4xl md:text-5xl lg:text-6xl xl:text-6xl'>FEATURED WORK</p><br/>
            <div className='w-[600px]  flex gap-3'>
                <div className='w-[40%] md:w-[50%] lg:w-[60%] xl:w-[60%] h-[30%] bg-white p-2'>
                <table class=" cursor-pointer w-[100%]  h-[100%]">
                    <tr >
                        <td className='border w-[30%] h-[10%]  px-5 py-px' ><img alt='a' src='/Assets/I NEXT BLACK LOGO.png' onClick={handleDainikClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/goodrich logo.png' onClick={handlegoodrichClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/Network 18 logo.png' onClick={handlenews18Click} /></td>
                    </tr>
                    <tr>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/Airworks logo.png' onClick={handleairworkClick}/></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/PTC logo.png' onClick={handleptcClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/DM records logo.png' /></td>
                    </tr>
                    <tr>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/infinity logo.png' onClick={handleinfinityClick}  /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px'><img alt='a' src='/Assets/LUBZOL NEW LOGO_1.png' onClick={handlelubzolClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px'><img alt='a' src='/Assets/Daler Mehndi logo.png' /></td>
                    </tr>
                    <tr>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/vetromodo logo.png' onClick={handlevetromodaClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/vetromoda DEVA logo.png' onClick={handledevaClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/india News logo.png' onClick={handleindianewsClick}/></td>
                    </tr>
                    <tr>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/PLATINA FINAL LOGO.png' onClick={handleplatinaClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px'><img alt='a' src='/Assets/royal grouse logo.png' onClick={handleroyalClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/IEEMA logo.png' onClick={handleieemaClick} /></td>
                    </tr>
                    <tr>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/alternate jeans logo.jpg' onClick={handlealtClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/Horizon Ocieanic logo.png' onClick={handlehorizonClick} /></td>
                        <td className='border w-[30%] h-[10%] px-5 py-px' ><img alt='a' src='/Assets/Luxcraft logo_line.png' onClick={handleluxcraftClick} /></td>
                    </tr>
                    </table>


                </div>

                <div className='w-[80px] md:w[100px] lg:w-[150px]  leading-[9px] md:leading-[11px] lg:leading-4 '>
                    <p className='text-slate-400 text-[9px] lg:text-[18px] md:text-[13px]' >
                    DAINIK JAGRAN INEXT <br/>
                    GOODRICH<br/>
                    AIRWORKS<br/>
                    NEWS18<br/>
                    INDIA NEWS<br/>
                    PTC<br/>
                    PLATINA<br/>
                    INFINITY <br/>
                    LUBZOL<br/>
                    ALT JEANS<br/>
                    VETROMODA<br/>
                    ROYAL GROUSE<br/>
                    CHHABRA 555<br/>
                    DEVA<br/>
                    LUXCRAFT<br/>
                    HWSSI<br/>
                    SUPVA UNIVERSITY<br/>
                    HORIZON OCEANIC<br/>

                    </p>
                </div>
            </div>   
        </div>
        <div className=" lg:w-3/4 md:w-3/4 justify-start items-start flex md:justify-center  md:items-center lg:justify-center  lg:items-center lg:justify-center  lg:items-center pt-5 md:pt-16  relative group ">
        <video
            loop          
            playsInline
            className='workvideo  h-[250px] md:h-[250px] lg:h-auto w-[55%] md:w-[100%] lg:w-[100%]'
            ref={videoRef}
            >
              <source src='/Assets/WS_WorkPg.mp4' type='video/mp4' />
              Your browser does not support the video tag.
            </video>
                  <button className="play-button2 absolute mt-10  md:top-1/2 md:left-1/2 xl:top-1/2 xl:left-1/2 lg:top-1/2 lg:left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity" onClick={toggleVideo}>
              {isPlaying ? (
                <FontAwesomeIcon icon={faPause} style={{ color: 'black', fontSize: '24px' }} />
              ) : (
                <FontAwesomeIcon icon={faPlay} style={{ color: 'black', fontSize: '24px' }} />
              )}
            </button>

        </div>
        
    </div>
    </div>
{/* awards */}

    <div className="relative w-full">
    <img alt='a' src="/Assets/WS_red.png" className=" top-0 w-full bg-no-repeat bg-cover top-0 h-[600px] " />
  <img alt='a' src="/Assets/WhiteSheet_W_blk.png" className=" top-0 absolute left-[-50%] w-[100%] md:w-[90%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[600px] " />
  <div className=" about absolute top-0 flex flex-col md:flex lg:flex xl:flex  md:flex-row lg:flex-row xl:flex-row md:space-x-[10%] lg:space-x-[30%] xl:space-x-[35%] p-6 md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-20 xl:py-24  ">
    <div className="text-center md:text-left">
      <h1 className="text-white text-4xl md:text-6xl lg:text-6xl xl:text-6xl">AWARD</h1>
    </div>
      <div >
        <p className="text-white text-left">
        Discover our collection of awards, a few of the many testaments to our unwavering commitment to excellence and innovative creative solutions.<br/><br/>


        ABBYS <br/>
        CAMPAIGN INDIA<br/>
        WANIFRA <br/>
        CAMPAIGN MEDIA360 SUMMIT AWARD<br/>
        INMA - GLOBAL MEDIA AWARDS<br/>

        </p>
      </div>
    </div>
  </div>

  {/* dainik jagran */}

        <div ref={DainikRef} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex space-y-[10%]  flex-col md:flex md:flex-row lg:flex-row lg:flex md:space-x-[20%] lg:space-x-[40%] p-10 w-full">
      <div className='lg:w-1/4 md:w-1/4'>
      
        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'>DAINIK JAGRAN INEXT</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
        Brand Strategy<br/> 
        Brand Expression.<br/>
        Print/Radio<br/>
        Communication.<br/>
        Digital.<br/>
        Coffee table Books<br/>

        </p>
      </div>
      <div className="lg:w-3/4 md:w-3/4 relative group ">
      <video src='/Assets/WS_inext_workpgav.mp4' 
      playsInline
      loop
      ref={InextRef} ></video>
       <button className=" absolute top-1/2 left-1/2 md:top-1/2 md:left-1/2 xl:top-1/2 xl:left-1/2 lg:top-1/2 lg:left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity" onClick={toggleVideo1}>
              {isPlayingInext ? (
                <FontAwesomeIcon icon={faPause} style={{ color: 'black', fontSize: '24px' }} />
              ) : (
                <FontAwesomeIcon icon={faPlay} style={{ color: 'black', fontSize: '24px' }} />
              )}
            </button>
      </div>
    </div>
  </div>


    {/* dainik jagran2 */}

        <div className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
    <div className='md:w-1/5 lg:w-1/5'>
      <p className='text-2xl md:text-2xl lg:text-2xl font-medium'>DAINIK JAGRAN INEXT</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
        Brand Strategy<br/> 
        Brand Expression.<br/>
        Print/Radio<br/>
        Communication.<br/>
        Digital.<br/>
        Coffee table Books<br/>

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
      <img alt='a' src='/Assets/print ad collage.png' />

      </div>
    </div>
  </div>




      {/* dainik jagran3 */}

        <div className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
      <div className='md:w-1/5 lg:w-1/5'>
      <p className='text-2xl md:text-2xl lg:text-2xl font-medium'>DAINIK JAGRAN INEXT</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
        Brand Strategy<br/> 
        Brand Expression.<br/>
        Print/Radio<br/>
        Communication.<br/>
        Digital.<br/>
        Coffee table Books<br/>

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
       <div>
        <img alt='a' src='/Assets/Inext_emailers_iphone.png'/>
       </div>
      </div>
    </div>
  </div>

      {/* dainik jagran4 */}

        <div className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
       <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
      <div className='md:w-1/5 lg:w-1/5'>
      <p className='text-2xl md:text-2xl lg:text-2xl font-medium'>DAINIK JAGRAN INEXT</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
        Brand Strategy<br/> 
        Brand Expression.<br/>
        Print/Radio<br/>
        Communication.<br/>
        Digital.<br/>
        Coffee table Books<br/>

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">

       <div>
        <img alt='a' src='/Assets/Inext_emailers_iphone_2option.png'/>
       </div>
      </div>
    </div>
  </div>

       {/* dainik jagran5 */}

        <div className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
      <div className='md:w-1/5 lg:w-1/5'>
      <p className='text-2xl md:text-2xl lg:text-2xl font-medium'>DAINIK JAGRAN INEXT</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
        Brand Strategy<br/> 
        Brand Expression.<br/>
        Print/Radio<br/>
        Communication.<br/>
        Digital.<br/>
        Coffee table Books<br/>

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
       <div>
        <img alt='a' src='/Assets/JCTB BOOK collage.png'/>
       </div>
      </div>
    </div>
  </div>

        {/* LUBZOL*/}

  <div ref={LubzolRef}  className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
       <div className="absolute top-0 flex space-y-[10%]  flex-col md:flex md:flex-row lg:flex-row lg:flex md:space-x-[20%] lg:space-x-[40%] p-10 w-full">
      <div className='lg:w-1/4 md:w-1/4'>

        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >LUBZOL</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 

        Brand Strategy<br/> 
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.<br/>
        </p>
      </div>
        <div className="lg:w-3/4 md:w-3/4 relative group ">
        <video src='/Assets/WS_trans_LINKD.mp4' 
        playsInline
        ref={LubzolvRef}
        loop ></video>
        <button className=" absolute top-1/2 left-1/2 md:top-1/2 md:left-1/2 xl:top-1/2 xl:left-1/2 lg:top-1/2 lg:left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity" onClick={toggleVideo2}>
              {isPlayingLubzol ? (
                <FontAwesomeIcon icon={faPause} style={{ color: 'black', fontSize: '24px' }} />
              ) : (
                <FontAwesomeIcon icon={faPlay} style={{ color: 'black', fontSize: '24px' }} />
              )}
            </button>
      </div>
    </div>
  </div>



     {/* LUBZOL2 */}

          <div className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
    <div className='md:w-1/5 lg:w-1/5'>
  <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >LUBZOL</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 

        Brand Strategy<br/> 
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.<br/>
        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
      <img alt='a' src='/Assets/WS_LUBZOL_work pg.png'/>
      
      </div>
    </div>
  </div>



       {/* GOODRICH */}

       <div ref={GoodrichRef} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
      <div className='md:w-1/5 lg:w-1/5'>
        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'>GOODRICH</p>
       <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
        Brand Strategy<br/> 
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.<br/>
        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
      <div>
      <img alt='a' src='/Assets/WS_workpg_goodrich.png'/>
      </div>
      </div>
    </div>
  </div>


     {/* Airworks */}

     <div ref={AirworkRef} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
     <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
      <div className='md:w-1/5 lg:w-1/5'>

        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >AIRWORKS</p> 
       <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
     
        Brand Expression.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.<br/>
        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
    <div>
      <img alt='a' src='/Assets/WS_work pg_airworks.png'/>
      </div>
      </div>
    </div>
  </div>



      {/*NEWS XINDIA NEWS */}

      <div ref={indianewsRef} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
      <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
      <div className='md:w-1/5 lg:w-1/5'>

        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >NEWS X INDIA NEWS</p> 
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
     
        Brand Expression.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.<br/>
        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
    <div>
      <img alt='a' src='/Assets/WS_workpg_indiaNew_x.png'/>
      </div>
      </div>
    </div>
  </div>

    {/*vetromoda */}

    <div ref={VETROMODARef} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
    <div className='md:w-1/5 lg:w-1/5'>

        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >VETROMODA</p>
       <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
      
        Brand Strategy<br/> 
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.

        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
    <div>
      <img alt='a' src='/Assets/WS_workPg_vetromods.png'/>
      </div>
      </div>
    </div>
  </div>


   {/*vetromoda2 */}

   <div className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
   <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
      <div className='md:w-1/5 lg:w-1/5'>

        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >VETROMODA</p>
       <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
      
        Brand Strategy<br/> 
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.

        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
    <div>
      <img alt='a' src='/Assets/WS_workPg_vetromoda_2.png'/>
      </div>
      </div>
    </div>
  </div>


   {/*vetromoda3 */}

   <div className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
   <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
<div className='md:w-1/5 lg:w-1/5'>

        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >VETROMODA</p>
       <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
      
        Brand Strategy<br/> 
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.

        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
    <div>
      <img alt='a' src='/Assets/WS_workPg_vetromoda_3.png'/>
      </div>
      </div>
    </div>
  </div>



    {/*PTC */}

    <div ref={ptcRef} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
      <div className='md:w-1/5 lg:w-1/5'>

        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >PTC</p> 
         <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
   
       
        Brand Expression.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.

        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
    <div>
      <img alt='a' src='/Assets/WS_workPg_PTC.png'/>
      </div>
      </div>
    </div>
  </div>


   {/*PLATINA */}

  <div ref={platinaRef} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
  <div className="absolute top-0 flex space-y-[10%]  flex-col md:flex md:flex-row lg:flex-row lg:flex md:space-x-[20%] lg:space-x-[40%] p-10 w-full">
      <div className='lg:w-1/4 md:w-1/4'>
        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >PLATINA</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
     
        Brand Strategy<br/> 
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.

        

        </p>
      </div>
      <div className="lg:w-3/4 md:w-3/4 relative group ">
      <video src='/Assets/WS_PLATINA BRANDING_NEW.mp4' 
      playsInline
      ref={PlatinaVRef}
      loop ></video>
      <button className=" absolute top-1/2 left-1/2 md:top-1/2 md:left-1/2 xl:top-1/2 xl:left-1/2 lg:top-1/2 lg:left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity" onClick={toggleVideo3}>
              {isPlayingPlatina ? (
                <FontAwesomeIcon icon={faPause} style={{ color: 'black', fontSize: '24px' }} />
              ) : (
                <FontAwesomeIcon icon={faPlay} style={{ color: 'black', fontSize: '24px' }} />
              )}
            </button>
      </div>
    </div>
  </div>


    {/*PLATINA2 */}

        <div className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
    <div className='md:w-1/5 lg:w-1/5'>
       <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >PLATINA</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
     
        Brand Strategy<br/> 
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.      

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
             <img alt='a' src='/Assets/WS_workPg_platina.png'  />
      </div>
    </div>
  </div>


    {/*INFINITY */}
  <div ref={infinityRef}  className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
       <div className="absolute top-0 flex space-y-[10%]  flex-col md:flex md:flex-row lg:flex-row lg:flex md:space-x-[20%] lg:space-x-[40%] p-10 w-full">
      <div className='lg:w-1/4 md:w-1/4'>
        <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >INFINITY</p> 
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
     
        Brand Strategy<br/> 
        Brand Naming. <br/>
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.<br/>
        Retail Design.

        

        </p>
      </div>
      <div className="lg:w-3/4 md:w-3/4 relative group ">
      <video src='/Assets/WS_infinity_N_VB.mp4' 
              playsInline
              ref={InfinityVRef}
              loop></video>
              <button className=" absolute top-1/2 left-1/2 md:top-1/2 md:left-1/2 xl:top-1/2 xl:left-1/2 lg:top-1/2 lg:left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity" onClick={toggleVideo4}>
              {isPlayingInfinity ? (
                <FontAwesomeIcon icon={faPause} style={{ color: 'black', fontSize: '24px' }} />
              ) : (
                <FontAwesomeIcon icon={faPlay} style={{ color: 'black', fontSize: '24px' }} />
              )}
            </button>
      </div>
    </div>
  </div>

  {/* infinity2 */}

    <div className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
    <div className='md:w-1/5 lg:w-1/5'>
      <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >INFINITY</p> 
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
     
        Brand Strategy<br/> 
        Brand Naming. <br/>
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.<br/>
        Retail Design.

        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
             <img alt='a' src='/Assets/glass poster_2.jpg'  />
      </div>
    </div>
  </div>


   {/* IEEMA */}

       <div ref={ieema} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
    <div className='md:w-1/5 lg:w-1/5'>
      <p className='text-2xl md:text-2xl lg:text-2xl font-medium	'>IEEMA</p>  
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'  >   
        Brand Strategy<br/> 
        Brand Expression.<br/>
        Visual/Verbal.<br/>
        Communication.


        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
             <img alt='a' src='/Assets/WS_workPg_ieema.png'  />
      </div>
    </div>
  </div>


      {/*LUXCRAFT */}
      <div ref={LUXCRAFTRef}  className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
       <div className="absolute top-0 flex space-y-[10%]  flex-col md:flex md:flex-row lg:flex-row lg:flex md:space-x-[20%] lg:space-x-[40%] p-10 w-full">
      <div className='lg:w-1/4 md:w-1/4'>
 <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >LUXCRAFT</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
        Brand Strategy<br/> 
        Brand Naming. <br/>
        Brand Identity. <br/>
        Brand Expression.<br/>
        Packaging.<br/>
        Visual/Verbal.<br/>
        Communication.<br/>
        Digital.<br/>
        Retail Design.
        </p>
      </div>
      <div className="lg:w-3/4 md:w-3/4 relative group ">
      <video src='/Assets/WS_Luxcraft_BD_REVISED fi_1.mp4' 
              playsInline
              ref={LuxcraftVRef}
              loop ></video>
              <button className=" absolute top-1/2 left-1/2 md:top-1/2 md:left-1/2 xl:top-1/2 xl:left-1/2 lg:top-1/2 lg:left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity" onClick={toggleVideo5}>
              {isPlayingLuxcraft ? (
                <FontAwesomeIcon icon={faPause} style={{ color: 'black', fontSize: '24px' }} />
              ) : (
                <FontAwesomeIcon icon={faPlay} style={{ color: 'black', fontSize: '24px' }} />
              )}
            </button>
      </div>
    </div>
  </div>



     {/* ALTERNATE JEANS */}

      <div ref={AltkRef} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
    <div className='md:w-1/5 lg:w-1/5'>
  <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >ALT/ JEANS</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
        Brand Identity<br/> 
        Brand Expression.<br/>
        Packaging<br/> 
        Visual/Verbal.<br/>
        Communication.<br/> 
        Digital.


        

        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
      <img alt='a' src='/Assets/WS_work pg_ALT jeans.png'  />
      </div>
    </div>
  </div>


  
     {/* ROYAL GROUSE */}

      <div ref={ROYALkRef} className=" workfeture relative bg-zinc-200 h-[500px] md:h-[500px] lg:h-[600px] w-full">
    <div className="absolute top-0 flex flex-col  md:flex lg:flex md:flex-row lg:flex-row md:space-x-[15%] lg:space-x-[20%] px-10 py-10 w-full">
    <div className='md:w-1/5 lg:w-1/5'>
  <p className='text-2xl md:text-2xl lg:text-2xl font-medium'  >ROYAL GROUSE</p>
        <p className='text-left text-sm md:text-lg font-medium lg:text-lg mt-10'> 
        Brand Identity<br/> 
        Brand Expression.<br/>
        Packaging<br/> 
        Visual/Verbal.<br/>
        Communication.<br/> 
        </p>
      </div>
      <div className="lg:w-4/5 md:w-4/5 flex flex-col gap-y-2 mt-16 ">
      <img alt='a' src='/Assets/WS_workpg_royal Grouse.png'  />
      </div>
    </div>
  </div>
 {/* Contactus */}
<div className="relative w-full">
  <img alt='a' src="/Assets/WS_red.png" className=" top-0 w-full bg-no-repeat bg-cover top-0 h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]" />
  <img alt='a' src="/Assets/WhiteSheet_W_blk.png" className=" top-0 absolute left-[-50%] w-[100%] md:w-[100%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]" />
  <div className=" contact absolute top-0 flex flex-col space-y-10  md:flex md:flex-row md:space-x-[10%] lg:flex lg:flex-row xl:flex-row xl:flex lg:space-x-[15%] xl:space-x-[20%] p-[1%] md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-24 xl:py-28  ">
    <div className="text-center md:text-left mt-6 ">
      <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-6xl">Get&nbsp;In&nbsp;Touch</h1>
    </div>
    <div className="text-white top-0 text-left relative">
      <img alt='a' src="/Assets/WS_conta us pg mag background.png" className="w-700" />
      <div className="absolute top-0 flex flex-col space-y-5 ml-4">
        <div className='flex   space-x-5 md:space-x-10'>
          <div className='flex flex-col space-y-5'>
             <p>
                  ASIA PACIFIC/INDIA<br />
                  WZ-298A, Naraina,<br />
                  New Delhi-110028. INDIA <br />
                  info@whitesheet.in <br/>
                  sourav@whitesheet.in
                </p>
                <p>www.whitehseet.in</p>

          </div>
          <div className='flex flex-col  space-y-5'>
          <p>
                OCEANIC/AUSTRALIA <br/>
                105, Lineham Drive,<br/>
                Cranbourne East, <br/>
                Vic 3977 Australia <br/>
                info@whitesheet.in

                </p>
                <div className="w-[150px] flex gap-2 pt-2">
                <a href='https://linkedin.com/company/whitesheet/'><img alt='a' src="/Assets/Linkedin_logo.png" className=" h-3" /></a>
                <a href='https://instagram.com/whitesheetmediawork/'> <img alt='a' src="/Assets/Insta_logo.png" className=" h-3" /></a>
                <a href='https://www.facebook.com/whitesheetmedia/'><img alt='a' src="/Assets/FB_logo.png" className=" h-3" /></a>
                <a href='https://in.pinterest.com/whitesheetmediaworks'><img alt='a' src="/Assets/Pinterest_logo.png" className=" h-3" /></a>
                <a href='https://www.youtube.com/@WhiteSheetMediaWorks'><img alt='a' src="/Assets/Youtube_logo.png" className=" h-3" /></a>                </div>
              

          </div>

        </div>
        <div>

        <form className="contact-inputs bg-white p-1 md:px-2 w-[95%]" onSubmit={handleFormSubmit} >
                  <div className="flex text-black space-x-2">
                    <input className=" w-40" placeholder="First Name" type='text' name='user_fname' autoComplete='off' required onChange={handleInputChange} />
                    <input className=" w-40 " placeholder="Last Name" type='text' name='user_lname' autoComplete='off' required onChange={handleInputChange} />
                  </div>
                  <div className="flex mt-2 space-x-2 text-black">
                    <input className=" w-40 " placeholder="Email" type='email' name='user_email' autoComplete='off' required onChange={handleInputChange} />
                    <input className=" w-40 " placeholder="Company" type='text' name='subject' autoComplete='off' required onChange={handleInputChange} />
                  </div>
                  <div className="mt-2 text-black">
                    <textarea placeholder="Add your message here" className="w-full" name='message' required onChange={handleInputChange} />
                  </div>
                  <div className='flex md:flex  md:space-x-1'>
                    <div className='flex flex-col text-black text-xs'>
                      <div>                     
                      <input type="checkbox" required/><lable> I understand and agree to the terms of submission & data processing. </lable>
                      </div>
                      <div>
                      <input type='checkbox' required /><lable> I’m not a robot. </lable>
                      </div>
                    </div>
                    <div>
                      <button className='text-white bg-black md:w-16 rounded' type='submit' >Submit</button>
                    </div>
                  </div>
        </form>

        <p className="pt-5">
                ©2023 White Sheet Media Work (P) Limited. All Right Reserved.
              </p>

        </div>
      
        
      </div>
    </div>
  </div>
</div>



      




 




        
      </div>

    </div>
  );
}
