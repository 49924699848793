import React from 'react'
import Navbar from './Navbar'
import '../index.css';


export default function () {
  return (
    <div className='overflow-hidden'>
    <Navbar />

    <div className="aboutus relative w-full">
      <img
        src="/Assets/WS_red.png"
        className=" background-image w-full bg-no-repeat bg-cover h-[100vh] md:h-[100vh] max-h-[100%] md:max-h-[100vh] lg:h-[100vh] xl:h-[100vh]"
        alt="Red Background"
      />
      <img
        src="/Assets/WhiteSheet_W_blk.png"
        className="absolute top-0 left-[-50%] w-[100%] md:w-[90%] lg:w-[100%] max-h-[100%] md:max-h-[100vh] lg:max-h-[100%] h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh]"
        alt="White Logo"
      />
      
      <div className="about absolute top-0 flex flex-col space-y-5 md:flex lg:flex xl:flex md:flex-row lg:flex-row xl:flex-row md:space-x-[8%] lg:space-x-[28%] xl:space-x-[35%] p-6 md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-10 xl:py-10">
        <div className="text-left md:text-left">
          <h1 className="text-white text-4xl md:text-6xl lg:text-6xl xl:text-6xl">ABOUT</h1>
        </div>
        <div>
          <p className="text-white leading-4 md:leading-4 lg:leading-5 xl:leading-5">
            White Sheet Media Works, a multi-disciplinary advertising and communication design agency with over 20 years of experience in marketing communication.<br />
            We are a team of seasoned professionals who have worked with aspiring brands to create stand-out communication that strikes a chord with the audience.<br /><br />
            Our expertise lies in developing effective marketing and communication strategies for brands across all streams, including social media, TV, print, radio, digital, and activation. We pride ourselves on our ability to deliver exceptional work in advertising and communication, graphics and identity, strategy and positioning, website and digital experience, packaging, and book designs.<br /><br />
            At White Sheet Media, we approach each project with conviction, passion, intelligence, and personal commitment, ensuring that we complement our clients in every shape, size, and industry. Our hybrid structure allows us to adapt to the unique needs of each client and provide tailor-made solutions that surpass their expectations.<br /><br />
            Whether you're a start-up looking to establish your brand or an established company seeking to revamp your marketing strategy, we are here to help you succeed.<br />
            Join us on this exciting journey and let's create something truly remarkable together.
          </p>
        </div>
      </div>
    </div>
  </div>
  
  )
}
