
import React from 'react';
import { useState, useEffect, useRef  } from 'react';
import Navbar from './Navbar';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

export default function Home() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const [formData, setFormData] = useState({
    user_fname: '',
    user_lname: '',
    user_email: '',
    subject: '',
    message: '',
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Check if all form fields are filled
    if (
      formData.user_fname &&
      formData.user_lname &&
      formData.user_email &&
      formData.subject &&
      formData.message
    ) {
      // Show a sweet alert when all fields are filled
      Swal.fire({
        icon: 'success',
        title: 'Thank You',
        text: 'We will revert to you shortly',
      });
      setFormData({
        user_fname: '',
        user_lname: '',
        user_email: '',
        subject: '',
        message: '',
      });
    } else {
      // If not all fields are filled, you can show an error alert
      Swal.fire({
        icon: 'error',
        title: 'Form Submission Error',
        text: 'Please fill out all the required fields.',
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleVideo = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };


  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     console.log('Timeout triggered');
  //     if (!isPlaying) {
  //       videoRef.current.play()
  //         .then(() => {
  //           setIsPlaying(true);
  //         })
  //         .catch((error) => {
  //           console.error('Autoplay failed:', error);
  //           setIsPlaying(false);
  //         });
  //     }
  //   }, 30000);
  
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [isPlaying]);
  
  

  useEffect(() => {
    const handleVisibilityChange = () => {
      console.log('Visibility changed:', document.visibilityState);
      if (document.visibilityState === 'visible') {
        if (isPlaying) {
          videoRef.current.play();
        }
      } else {
        videoRef.current.pause();
      }
    };
    
  
    const handleScroll = () => {
      videoRef.current.pause();
      setIsPlaying(false);

    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isPlaying]);
  




  return (
    <main className="min-h-screen bg-red-100">
    <div className="relative overflow-hidden w-full h-auto">
  <div className="main">
    <div  className="w-full  top-0 h-[700px] md:h-[700px] lg:h-[100vh]" >
    
    <img src="/Assets/WhiteSheet_W_blk.png" className="absolute top-0 right-[-50%] w-[100%] md:w-[100%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[700px] md:h-[700px] lg:h-[100vh]" />

    <nav className="navbar2 absolute top-0 h-[100px] md:h-[100px] lg:h-[100px] w-full bg-zinc-700 bg-opacity-20">
      <img src="/Assets/WhiteSheet_logo_white.png" className="absolute top-0 left-0 w-[250px] md:w-[300px] lg:w-[300px] p-7" />
      <div className="absolute top-0 right-0 p-10 flex md:flex lg:flex gap-1 md:gap-2 lg:gap-2">
        <a href="https://linkedin.com/company/whitesheet/">
          <img src="/Assets/Linkedin_logo.png" className="h-3" />
        </a>
        <a href="https://instagram.com/whitesheetmediawork/">
          <img src="/Assets/Insta_logo.png" className="h-3" />
        </a>
        <a href="https://www.facebook.com/whitesheetmedia/">
          <img src="/Assets/FB_logo.png" className="h-3" />
        </a>
        <a href="https://in.pinterest.com/whitesheetmediaworks">
          <img src="/Assets/Pinterest_logo.png" className="h-3" />
        </a>
        <a href="https://www.youtube.com/@WhiteSheetMediaWorks">
          <img src="/Assets/Youtube_logo.png" className="h-3" />
        </a>
      </div>
    </nav>
    <div className="absolute top-[20%] md:top-[12%] lg:top-[20%] px-[5%] py-[5%]">
      <div className=" flex flex-col md:flex md:flex-col  lg:flex lg:flex-row  lg:justify-between   ">
        <div className="text-white text-left lg:w-3/5">
          <p className="font-light text-3xl md:text-5xl lg:text-6xl">WE CREATE <br/> TRANSFORMATIVE,<br/> DISRUPTIVE BRAND<br/> EXPERIENCES.</p>
          <div className="text-sm md:text-lg lg:text-xl leading-tight mt-3 md:mt-5 lg:mt-9">EVOLVING GROWTH. SUCCESS FURTHERMORE.</div>
        </div>
        <div className="lg:w-2/5">
        <div className="video-container pt-5 md:pt-7 lg:pt-10 md:pl-5 relative group">
        <video
            loop
            
            playsInline
            className='h-[250px] md:h-[250px] lg:h-auto'
            ref={videoRef}
                >
                  <source src='/Assets/Whitesheet_intro_BW_623.mp4' type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
                      <button className="play-button absolute mt-5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity" onClick={toggleVideo}>
                  {isPlaying ? (
                    <FontAwesomeIcon icon={faPause} style={{ color: 'black', fontSize: '24px' }} />
                  ) : (
                    <FontAwesomeIcon icon={faPlay} style={{ color: 'black', fontSize: '24px' }} />
                  )}
                </button>
    </div>


        </div>
      </div>
    </div>
</div>
  </div>
</div>
<div className=" sticky top-0 z-50">
<Navbar />
</div>
<div className='overflow-hidden'>
<div className=" home w-full bg-black h-[650px] md:h-[650px] lg:h-[100vh] xl:h-[100vh] relative">
<img src="/Assets/WhiteSheet_W_RED.png" className=" home-img absolute left-[-50%] w-[100%] md:w-[90%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[650px] md:h-[650px] lg:h-[100vh] xl:h-[100vh]" />
  <div className=" home-para  absolute  md:ml-[35%] lg:ml-[44%] py-[10%] px-[5%] md:p-10 lg:p-14 xl:p-20 xl:ml-[45%] text-left">
    <p className="text-white leading-4 md:leading-4 lg:leading-5 xl:leading-6">
      Welcome to our world, where freshness meets passion and conviction drives stunning results. We are more than just a team of experts; we are disruptors who embrace simplicity, communicate with purpose, and engage and excite our clients every step of the way.<br/><br/>
      At our core, we are committed to driving brand performance and delivering unparalleled value to our clients. Whether you're looking to revitalize your brand, establish a new identity, or disrupt the market, we are here to help you achieve your goals.<br/><br/>
      So, what sets us apart? Our ability to complement our clients' vision and goals with our expertise and creativity, resulting in solutions that not only meet but exceed expectations. We are not afraid to disrupt the status quo, challenge conventional thinking, and drive innovation that sets you apart from the competition. So come on in, take a look around, and let us show you how we can help you unleash your potential and achieve stunning results.<br /><br />
      <p className="text-red-800 leading-4 md:leading-4 lg:leading-5 xl:leading-6 ">
        FRESH, PASSIONATE, WORK WITH CONVICTION. COMPLEMENT. ENGAGE.<br /> COMMUNICATE. EXCITE. LEARN. SIMPLE. DRIVE BRAND PERFORMANCE.<br />
        GET STUNNING RESULTS.
      </p>
    </p>
  </div>
</div>


{/* About */}
<div  className="relative w-full">
  <div  className=" top-0 w-full bg-no-repeat bg-cover top-0 h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]">
  <img src="/Assets/WhiteSheet_W_blk.png" className=" top-0 absolute left-[-50%] w-[100%] md:w-[90%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]" />
  <div className=" about absolute top-0 flex flex-col space-y-10  md:flex md:flex-row lg:flex lg:flex-row  xl:flex xl:flex-row md:space-x-[8%] lg:space-x-[28%] xl:space-x-[35%] p-6 md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-20 xl:py-24  ">
    <div className="text-left md:text-left">
      <h1 className="text-white text-4xl md:text-6xl lg:text-6xl xl:text-6xl">ABOUT</h1>
    </div>
    <div className=" ">
      <p className="text-white leading-4 md:leading-4 lg:leading-5 xl:leading-5 top-0 ">
        White Sheet Media Works, a multi-disciplinary advertising and communication design agency with over 20 years of experience in marketing communication.<br/>
        We are a team of seasoned professionals who have worked with aspiring brands to create stand-out communication that strikes a chord with the audience.<br/><br/>
        Our expertise lies in developing effective marketing and communication strategies for brands across all streams, including social media, TV, print, radio, digital, and activation. We pride ourselves on our ability to deliver exceptional work in advertising and communication, graphics and identity, strategy and positioning, website and digital experience, packaging, and book designs.<br/><br/>
        At White Sheet Media, we approach each project with conviction, passion, intelligence, and personal commitment, ensuring that we complement our clients in every shape, size, and industry. Our hybrid structure allows us to adapt to the unique needs of each client and provide tailor-made solutions that surpass their expectations.<br/><br/>
        Whether you're a start-up looking to establish your brand or an established company seeking to revamp your marketing strategy, we are here to help you succeed.<br/> 
        Join us on this exciting journey and let's create something truly remarkable together.
      </p>
    </div>
  </div>
  </div>
</div>


{/* Office */}
<div  className="relative w-full">
  <div className=" top-0 w-full bg-no-repeat bg-cover top-0 h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]">
  <img src="/Assets/WhiteSheet_W_blk.png" className=" top-0 absolute left-[-50%] w-[100%] md:w-[100%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]" />
  <div className=" Office absolute top-0 flex flex-col space-y-10 md:flex md:flex-row lg:flex lg:flex-row xl:flex xl:flex-row md:space-x-[8%] lg:space-x-[20%] xl:space-x-[20%] p-10 md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-24 xl:py-28  ">
    <div className="text-left md:text-left">
      <h1 className="text-white text-4xl md:text-6xl lg:text-6xl xl:text-6xl">Offices</h1>
    </div>
    <div className="text-white text-left relative">
      <img src="/Assets/WS_conta us pg mag background.png" className="w-700" />
      <div className="absolute lg:ml-[30%] xl:ml-[30%] top-0">
        <p>
          ASIA PACIFIC/INDIA<br />
          WZ-298A, Naraina, <br />
          New Delhi-110028. INDIA <br />
          info@whitesheet.in | sourav@whitesheet.in<br /><br />

          OCEANIC/AUSTRALIA <br />
          105, Lineham Drive, Cranbourne East, <br />
          Vic 3977 Australia <br />
          info@whitesheet.in <br /><br />

          www.whitehseet.in
        </p>
        <div className="w-[100px] flex gap-2">
        <a href='https://linkedin.com/company/whitesheet/'><img src="/Assets/Linkedin_logo.png" className=" h-3" /></a>
        <a href='https://instagram.com/whitesheetmediawork/'> <img src="/Assets/Insta_logo.png" className=" h-3" /></a>
        <a href='https://www.facebook.com/whitesheetmedia/'><img src="/Assets/FB_logo.png" className=" h-3" /></a>
        <a href='https://in.pinterest.com/whitesheetmediaworks'><img src="/Assets/Pinterest_logo.png" className=" h-3" /></a>
        <a href='https://www.youtube.com/@WhiteSheetMediaWorks'><img src="/Assets/Youtube_logo.png" className=" h-3" /></a>
        </div>
        <p className="pt-[100px]">
          ©2023 White Sheet Media Work (P) Limited. All Right Reserved.
        </p>
      </div>
    </div>
  </div>
  </div>
</div>


{/* Contactus */}
<div  className="relative w-full">
  <div className=" top-0 w-full bg-no-repeat bg-cover top-0 h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]">
  <img src="/Assets/WhiteSheet_W_blk.png" className=" top-0 absolute left-[-50%] w-[100%] md:w-[100%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[700px] md:h-[700px] lg:h-[100vh] xl:h-[100vh]" />
  <div className=" contact absolute top-0 flex flex-col space-y-10  md:flex md:flex-row md:space-x-[10%] lg:flex lg:flex-row xl:flex-row xl:flex lg:space-x-[10%] xl:space-x-[10%] p-[1%] md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-24 xl:py-28  ">
    <div className="text-left md:text-left mt-6 ">
      <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-6xl">Get&nbsp;In&nbsp;Touch</h1>
    </div>
    <div className="text-white top-0 text-left relative">
      <img src="/Assets/WS_conta us pg mag background.png" className="w-700" />
      <div className="absolute  top-0 flex flex-col space-y-5 ml-4 lg:ml-[20%] xl:ml-[20%]">
        <div className='flex   space-x-5 md:space-x-10'>
          <div className='flex flex-col space-y-5'>
             <p>
                  ASIA PACIFIC/INDIA<br />
                  WZ-298A, Naraina,<br />
                  New Delhi-110028. INDIA <br />
                  info@whitesheet.in <br/>
                  sourav@whitesheet.in
                </p>
                <p>www.whitehseet.in</p>

          </div>
          <div className='flex flex-col  space-y-5'>
          <p>
                OCEANIC/AUSTRALIA <br/>
                105, Lineham Drive,<br/>
                Cranbourne East, <br/>
                Vic 3977 Australia <br/>
                info@whitesheet.in

                </p>
                <div className="w-[150px] flex gap-2 pt-2">
                <a href='https://linkedin.com/company/whitesheet/'><img src="/Assets/Linkedin_logo.png" className=" h-3" /></a>
                <a href='https://instagram.com/whitesheetmediawork/'> <img src="/Assets/Insta_logo.png" className=" h-3" /></a>
                <a href='https://www.facebook.com/whitesheetmedia/'><img src="/Assets/FB_logo.png" className=" h-3" /></a>
                <a href='https://in.pinterest.com/whitesheetmediaworks'><img src="/Assets/Pinterest_logo.png" className=" h-3" /></a>
                <a href='https://www.youtube.com/@WhiteSheetMediaWorks'><img src="/Assets/Youtube_logo.png" className=" h-3" /></a>                </div>
              

          </div>

        </div>
        <div>

        <form className="contact-inputs bg-white p-1 md:px-2 w-[95%]"  onSubmit={handleFormSubmit}  >
                  <div className="flex text-black space-x-2">
                    <input className=" w-[50%]" placeholder="First Name" type='text' name='user_fname' autoComplete='off' required onChange={handleInputChange}/>
                    <input className=" w-[50%] " placeholder="Last Name" type='text' name='user_lname' autoComplete='off' required  onChange={handleInputChange}/>
                  </div>
                  <div className="flex mt-2 space-x-2 text-black">
                    <input className=" w-[50%] " placeholder="Email" type='email' name='user_email' autoComplete='off' required  onChange={handleInputChange}/>
                    <input className=" w-[50%] " placeholder="Company" type='text' name='subject' autoComplete='off' required onChange={handleInputChange}/>
                  </div>
                  <div className="mt-2 text-black">
                    <textarea placeholder="Add your message here" className="w-full" name='message' onChange={handleInputChange} />
                  </div>
                  <div className='flex md:flex  md:space-x-1'>
                    <div className='flex flex-col text-black text-xs'>
                      <div>                     
                      <input type="checkbox" required/><lable> I understand and agree to the terms of submission & data processing. </lable>
                      </div>
                      <div>
                      <input type='checkbox' required /><lable> I’m not a robot. </lable>
                      </div>
                    </div>
                    <div>
                      <button className='text-white bg-black md:w-16 rounded' type='submit' >Submit</button>
                    </div>
                  </div>
        </form>

        <p className="pt-5">
                ©2023 White Sheet Media Work (P) Limited. All Right Reserved.
              </p>

        </div>
      
        
      </div>
    </div>
  </div>
</div>
</div>
</div>



    </main>
  );
}
