import React from 'react';
import { useState } from 'react';
import Navbar from './Navbar';
import '../index.css';
import Swal from 'sweetalert2';

export default function ContactUs2() {
 
    const [formData, setFormData] = useState({
    user_fname: '',
    user_lname: '',
    user_email: '',
    subject: '',
    message: '',
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
  
    if (
      formData.user_fname &&
      formData.user_lname &&
      formData.user_email &&
      formData.subject &&
      formData.message
    ) {
      Swal.fire({
        icon: 'success',
        title: 'Form Submitted',
        text: 'Your form has been successfully submitted!',
      });
  
      // Clear the input fields by resetting formData to an empty object
      setFormData({
        user_fname: '',
        user_lname: '',
        user_email: '',
        subject: '',
        message: '',
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Form Submission Error',
        text: 'Please fill out all the required fields.',
      });
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className='overflow-hidden'>
     <Navbar/>
     <div  className="aboutus relative w-full">
  <img alt='a' src="/Assets/WS_red.png" className=" top-0 w-full bg-no-repeat bg-cover top-0 h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh]" />
  <img alt='a' src="/Assets/WhiteSheet_W_blk.png" className=" top-0 absolute left-[-50%] w-[100%] md:w-[100%] lg:w-[100%] max-w-[100%] md:max-w-[100%] lg:max-w-[100%]  h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh]" />
  <div className=" contact absolute top-0 flex flex-col space-y-10  md:flex md:flex-row md:space-x-[10%] lg:flex lg:flex-row xl:flex-row xl:flex lg:space-x-[15%] xl:space-x-[15%] p-[1%] md:p-10 lg:p-12 xl:p-14 md:py-20 lg:py-10 xl:py-10  ">
    <div className="text-left md:text-left mt-6 ">
      <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-6xl">Get&nbsp;In&nbsp;Touch</h1>
    </div>
    <div className="text-white top-0 text-left relative">
      <img alt='a' src="/Assets/WS_conta us pg mag background.png" className="w-700" />
      <div className="absolute top-0 flex flex-col space-y-5 ml-4 lg:ml-[15%]">
        <div className='flex   space-x-5 md:space-x-10'>
          <div className='flex flex-col space-y-5'>
             <p>
                  ASIA PACIFIC/INDIA<br />
                  WZ-298A, Naraina,<br />
                  New Delhi-110028. INDIA <br />
                  info@whitesheet.in <br/>
                  sourav@whitesheet.in
                </p>
                <p>www.whitehseet.in</p>

          </div>
          <div className='flex flex-col  space-y-5'>
          <p>
                OCEANIC/AUSTRALIA <br/>
                105, Lineham Drive,<br/>
                Cranbourne East, <br/>
                Vic 3977 Australia <br/>
                info@whitesheet.in

                </p>
                <div className="w-[150px] flex gap-2 pt-2">
                <a href='https://linkedin.com/company/whitesheet/'><img alt='a' src="/Assets/Linkedin_logo.png" className=" h-3" /></a>
                <a href='https://instagram.com/whitesheetmediawork/'> <img alt='a' src="/Assets/Insta_logo.png" className=" h-3" /></a>
                <a href='https://www.facebook.com/whitesheetmedia/'><img alt='a' src="/Assets/FB_logo.png" className=" h-3" /></a>
                <a href='https://in.pinterest.com/whitesheetmediaworks'><img alt='a' src="/Assets/Pinterest_logo.png" className=" h-3" /></a>
                <a href='https://www.youtube.com/@WhiteSheetMediaWorks'><img alt='a' src="/Assets/Youtube_logo.png" className=" h-3" /></a>                </div>
              

          </div>

        </div>
        <div>

        <form className="contact-inputs bg-white p-1 md:px-2 w-[95%]" onSubmit={handleFormSubmit} >
                  <div className="flex text-black space-x-2">
                    <input className=" w-40" placeholder="First Name" type='text' name='user_fname' autoComplete='off' required onChange={handleInputChange}/>
                    <input className=" w-40 " placeholder="Last Name" type='text' name='user_lname' autoComplete='off' required onChange={handleInputChange} />
                  </div>
                  <div className="flex mt-2 space-x-2 text-black">
                    <input className=" w-40 " placeholder="email" type='email' name='user_email' autoComplete='off' required onChange={handleInputChange} />
                    <input className=" w-40 " placeholder="Company" type='text' name='subject' autoComplete='off' required onChange={handleInputChange} />
                  </div>
                  <div className="mt-2 text-black">
                    <textarea placeholder="Add your message here" className="w-full" name='message' required onChange={handleInputChange}/>
                  </div>
                  <div className='flex md:flex  md:space-x-1'>
                    <div className='flex flex-col text-black text-xs'>
                      <div>                     
                      <input type="checkbox" required  /><lable> I understand and agree to the terms of submission & data processing. </lable>
                      </div>
                      <div>
                      <input type='checkbox' required /><lable> I’m not a robot. </lable>
                      </div>
                    </div>
                    <div>
                      <button className='text-white bg-black md:w-16 rounded' type='submit' >Submit</button>
                    </div>
                  </div>
        </form>

        <p className="pt-5">
                ©2023 White Sheet Media Work (P) Limited. All Right Reserved.
              </p>

        </div>
      
        
      </div>
    </div>
  </div>
</div>
    </div>
  );
}

