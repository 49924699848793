import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';

function CookiesPopup() {
  const [showPopup, setShowPopup] = useState(
    localStorage.getItem('cookiesAccepted') !== 'true'
  );

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowPopup(false);
  };

  return (
    <div className={`cookies-popup ${showPopup ? 'show' : ''}`}>
      {showPopup && (
        <div className="cookies-content">
         <div className=' flex text-left'>
         <p>
            This website uses cookies to enhance the user experience.{' '}
            <span>
              Learn more in our{' '}
              <a href="/T&c" style={{ color: 'blue' }}>
                Terms and Conditions
              </a>
              .
            </span>
            </p>

            <button onClick={handleAcceptCookies} className="accept-button">
              Accept
            </button>
         </div>
          
          
        </div>
      )}
      <CookieConsent
        location="bottom"
        buttonText="Got it!"
        cookieName="myCookieConsent"
        style={{ display: 'none' }}
        expires={150}
      />
    </div>
  );
}

export default CookiesPopup;
